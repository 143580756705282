import React from "react"
import Seo from "../components/molecules/Seo"
import { withTheme } from "styled-components"
import ContentHolder from "../components/organisms/ContentHolder"
import Splash from "../components/organisms/Splash"
import ServiceSection1 from "../components/organisms/ServiceSection1"
import ServiceSection2 from "../components/organisms/ServiceSection2"
import ServiceSection3 from "../components/organisms/ServiceSection3"
import ArrowText from "../components/atoms/ArrowText"
import thumb from "../../static/images/cw_home.png"

const Services = ({ theme }) => (
  <>
    <Seo
      title="Services | Craig Walker"
      thumb={thumb}
      description="Every client and project is different, but many of the challenges are similar."
    />
    <Splash
      bg={theme.colours.yellow}
      text={
        <>
          <b>We design the approach</b> <br />
          to create the best solution.
        </>
      }
    >
      <ArrowText>
        <p>
          While every client and project is different, many of the challenges
          are similar.
        </p>
        <p>Our three core services</p>
      </ArrowText>
    </Splash>
    <ContentHolder>
      <ServiceSection1 />
      <ServiceSection2 />
      <ServiceSection3 />
    </ContentHolder>
  </>
)

export default withTheme(Services)
