import React from "react"
import MakeRealImage from "../../assets/services-2.inline.svg"
import TickLi from "../atoms/TickLi"
import { Link } from "gatsby"
import IconArrow from "../atoms/iconArrow"
import ServiceSectionHeading from "../molecules/ServiceSectionHeading"
import ServiceSectionLists from "../molecules/ServiceSectionLists"
import ServiceSectionProjectTile from "../molecules/ServiceSectionProjectTile"

function ServiceSection2() {
  return (
    <>
      <ServiceSectionHeading
        svg={<MakeRealImage />}
        title="MakeReal"
        number={2}
      >
        <p className="h4">
          <b>
            As designers we understand the need to quickly create and test
            ideas. Rapid prototyping helps your team quickly establish if their
            idea has value to both users and the business.
          </b>
        </p>

        <p className="h4">
          Using a variety of design approaches, we help your team move forward
          quickly by turning ideas into visual concepts, experiences, clickable
          websites or even workshops that we use to test the idea with users and
          technical experts.
        </p>
      </ServiceSectionHeading>

      <ServiceSectionLists>
        <div>
          <h3>What we do:</h3>
          <p>
            With a focus on turning ideas into tangible concepts that people can
            engage with and test,&nbsp;we:
          </p>
          <ul>
            <TickLi>Understand your vision for the idea</TickLi>
            <TickLi>Help you generate concepts to test</TickLi>
            <TickLi>
              Facilitate customer, staff and stakeholder&nbsp;testing
            </TickLi>
            <TickLi>
              Capture and workshop insights, iterating and synthesising feedback
              and next&nbsp;steps
            </TickLi>
          </ul>
        </div>
        <div>
          <h3>What you get:</h3>
          <p>
            To effectively communicate the concepts, we&nbsp;develop
            high-fidelity materials:
          </p>
          <ul>
            <TickLi>Validated concepts</TickLi>
            <TickLi>MVP/prioritised features</TickLi>
            <TickLi>Working prototype</TickLi>
            <TickLi>
              Communications tools to share with&nbsp;stakeholders
            </TickLi>
          </ul>
        </div>
      </ServiceSectionLists>

      <ServiceSectionProjectTile image="boral-new-00003.jpg">
        <p className="h3">
          Accelerate your ideas and speed to market with rapid prototyping.
        </p>
        <p className="arrow-link" style={{ marginBottom: "0" }}>
          <IconArrow />
          <span>MakeReal in action</span>
        </p>
        <p className="arrow-link" style={{ marginTop: "0" }}>
          <div style={{ opacity: "0" }}>
            <IconArrow />
          </div>
          <div>
            {/* <Link to="/privacy-tech-meta">
              <span>Meta: Privacy Enhancing Technologies</span>
            </Link> */}
            <Link to="/www-foundation">
              <span>Web Foundation: Gender-Based Abuse</span>
            </Link>
            {/* <Link to="/d-ford">
              <span>Ford: Future Focused Prototypes</span>
            </Link> */}
          </div>
        </p>
      </ServiceSectionProjectTile>
    </>
  )
}

export default ServiceSection2
