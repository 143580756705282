import React from "react"
import TickLi from "../atoms/TickLi"
import { Link } from "gatsby"
import IconArrow from "../atoms/iconArrow"
import ServiceSectionHeading from "../molecules/ServiceSectionHeading"
import ServiceSectionLists from "../molecules/ServiceSectionLists"
import ServiceSectionProjectTile from "../molecules/ServiceSectionProjectTile"

function ServiceSection1() {
  return (
    <>
      <ServiceSectionHeading title="KnowNow">
        <p>
          As a research-led studio, we believe in casting a wide net to inform
          and inspire project teams, and continually find innovative ideas and
          new spaces.
        </p>
        <p>
          Using a mix of research techniques, we generate a comprehensive
          picture of the problem space and the unique set of user needs to help
          teams shift their thinking and develop innovative and competitive new
          solutions.
        </p>
      </ServiceSectionHeading>

      <ServiceSectionLists>
        <div>
          <h3>What we do:</h3>
          <p>
            Using our Powers of Ten research framework, we facilitate your team
            towards a deep understanding of an issue.
          </p>
          <ul>
            <TickLi>
              Global content scan with our research partner Notion.ai
            </TickLi>
            <TickLi>
              Global sense-check with international researchers and experts
            </TickLi>
            <TickLi>
              Ethnographic research with users and frontline staff
            </TickLi>
            <TickLi>
              Synthesis workshops to generate insights and define the problem we
              are trying to solve
            </TickLi>
          </ul>
        </div>
        <div>
          <h3>What you get:</h3>
          <p>
            To effectively communicate the insights, we&nbsp;develop
            high-fidelity materials:
          </p>
          <ul>
            <TickLi>Vision books to capture research and key insights</TickLi>
            <TickLi>
              High-fidelity concept sketches to help people understand the
              opportunities
            </TickLi>
            <TickLi>
              Behavioural personas/segmentation aligned to your strategy and
              organisations
            </TickLi>
            <TickLi>
              Research video/highlights to help communicate the deep learning
              from users
            </TickLi>
          </ul>
        </div>
      </ServiceSectionLists>

      <ServiceSectionProjectTile image="aus-post-new-00001.jpg">
        <p>Data and insights to power your design thinking.</p>
        <p className="arrow-link" style={{ marginBottom: "0" }}>
          <IconArrow />
          <span>KnowNow in action</span>
        </p>
        <p className="arrow-link" style={{ marginTop: "0" }}>
          <div style={{ opacity: "0" }}>
            <IconArrow />
          </div>
          <div>
            <Link to="/australia-post">
              <span>Australia Post: Welcome Service</span>
            </Link>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to="https://newworkstateofmind.craigwalker.com.au/"
            >
              <span>WorkSafe &amp; DSC: The Future of Work</span>
            </Link>
            <Link to="/anglicare-homely-homes">
              <span>Anglicare: Homely Homes</span>
            </Link>
          </div>
        </p>
      </ServiceSectionProjectTile>
    </>
  )
}

export default ServiceSection1
