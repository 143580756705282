import React from "react"
import FrameWorkImage from "../../assets/services-3.inline.svg"
import TickLi from "../atoms/TickLi"
import { Link } from "gatsby"
import IconArrow from "../atoms/iconArrow"
import ServiceSectionHeading from "../molecules/ServiceSectionHeading"
import ServiceSectionLists from "../molecules/ServiceSectionLists"
import ServiceSectionProjectTile from "../molecules/ServiceSectionProjectTile"

function ServiceSection3() {
  return (
    <>
      <ServiceSectionHeading
        svg={<FrameWorkImage />}
        title="FrameWork"
        number={3}
      >
        <p className="h4">
          <b>
            To support the successful implementation of your digital solutions,
            we help you fill the gap between strategy and execution with a
            portfolio of CX guidelines to drive the planning, prioritisation and
            delivery of user-centred digital programs.
          </b>
        </p>
        <p className="h4">
          Designing down from the vision and strategy, we bring to life the
          future CX streams, working with technology, distribution and product
          teams to capture the key use cases and journeys that express the scope
          of the program.
        </p>
      </ServiceSectionHeading>

      <ServiceSectionLists>
        <div>
          <h3>What we do:</h3>
          <p>
            We use existing content and research to help us form a perspective
            on the proposed CX, working through:
          </p>
          <ul>
            <TickLi>A research review of all existing work to&nbsp;date</TickLi>
            <TickLi>
              Working with programme leadership to develop target states
            </TickLi>
            <TickLi>
              Holding alignment workshops with key&nbsp;stakeholders
            </TickLi>
            <TickLi>
              Creating visual tools such as journey and touchpoint maps
            </TickLi>
          </ul>
        </div>
        <div>
          <h3>What you get:</h3>
          <p>
            To help communicate the concepts effectively and tangibly, our
            visual design team develops high fidelity materials including:
          </p>
          <ul>
            <TickLi>Consolidated CX view</TickLi>
            <TickLi>Future state journeys</TickLi>
            <TickLi>Design principles</TickLi>
            <TickLi>Communications tools</TickLi>
          </ul>
        </div>
      </ServiceSectionLists>

      <ServiceSectionProjectTile image="mirvac-new-1.jpg">
        <p className="h3">Align your vision across design and development</p>
        <p className="arrow-link" style={{ marginBottom: "0" }}>
          <IconArrow />
          <span>FrameWork in action</span>
        </p>
        <p className="arrow-link" style={{ marginTop: "0" }}>
          <div style={{ opacity: "0" }}>
            <IconArrow />
          </div>
          <div>
            <Link to="/mirvac">
              <span>Mirvac: Broadway’s Guest Experience</span>
            </Link>
            <Link to="/nsw-health">
              <span>NSW Health: Supporting At-Risk Groups</span>
            </Link>
            <Link to="/anglicare-homely-homes">
              <span>Anglicare: Homely Homes</span>
            </Link>
          </div>
        </p>
      </ServiceSectionProjectTile>
    </>
  )
}

export default ServiceSection3
