import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Container from '../atoms/Container';
import Logo from '../atoms/Logo';

const Holder = styled.div`
  position: relative;
  padding-bottom: 2rem;
`;

const Bg = styled.div`
  background-color: ${props => props.theme.colours.white};
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  @media ( ${props => props.theme.breakpoints.sm} ) {
    top: 6rem;
  }
`;

const Inner = styled.div`
  background-color: ${props => props.theme.colours.white};
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  padding: 2rem 0;
  @media ( ${props => props.theme.breakpoints.sm} ) {
    padding: 2rem 1rem;
  }
  @media ( ${props => props.theme.breakpoints.md} ) {
    grid-template-columns: 2rem 11fr;
    padding: 2rem;
    grid-gap: 2rem;
  }
  @media ( ${props => props.theme.breakpoints.md} ) {
    margin: 0 -2rem;
  }
`;

const Content = styled.div`
  h2 {
    font-weight: bold;
    ${props => props.theme.fluidType( 8 )};
  }
  h2 + p {
    font-weight: bold;
    ${props => props.theme.fluidType( 5 )};
    margin-bottom: 2rem;
  }
  > :first-child { margin-top: 0; }
  > :last-child { margin-bottom: 0; }
`;

const Sidebar = styled.div`
  position: relative;
  display: none;
  @media ( ${props => props.theme.breakpoints.md} ) {
    display: block;
  }
  .logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-50%) rotate(90deg);
    transform-origin: bottom left;
  }
`;

function ServiceSectionHeading( { title, children } ) {
  return (
    <Holder>
      <Bg/>
      <Container margins={false}>
        <Inner>
          <Sidebar>
            <Logo/>
          </Sidebar>
          <Content>
            <h2 className="sans">{title}</h2>
            {children}
          </Content>
        </Inner>
      </Container>
    </Holder>
  )
}

ServiceSectionHeading.propTypes = {
  title: PropTypes.string.isRequired,
};

export default ServiceSectionHeading;
