import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import Image from "../atoms/Image"
import Container from "../atoms/Container"
import "intersection-observer"
import { useInView } from "react-intersection-observer"

const Holder = styled.div`
  position: relative;
  padding-bottom: 20rem;
  @media (${(props) => props.theme.breakpoints.xl}) {
    margin-bottom: -5rem;
  }
`

const ImageHolder = styled.div`
  position: absolute;
  top: 12rem;
  left: 0;
  bottom: 0;
  right: 0;
  @media (${(props) => props.theme.breakpoints.md}) {
    display: ${(props) => (props.inView ? "block" : "none")};
    position: fixed;
    top: 0;
    z-index: -1;
    height: auto;
    padding-bottom: 0;
  }
`

const TextHolder = styled.div`
  position: relative;
`

const Bg = styled.div`
  background-color: ${(props) => props.theme.colours.grey};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 12rem;
  @media (${(props) => props.theme.breakpoints.md}) {
    height: 6rem;
  }
`

const TextInner = styled.div`
  background-color: ${(props) => props.theme.colours.white};
  position: relative;
  z-index: 10;
  width: 100%;
  padding: 2rem;
  @media (${(props) => props.theme.breakpoints.xl}) {
    width: 50%;
  }
  p:first-child {
    ${(props) => props.theme.fluidType(8)};
    font-family: "Lora", serif;
    font-weight: normal;
    margin-bottom: 3rem;
  }
  > :first-child {
    margin-top: 0;
  }
  > :last-child {
    margin-bottom: 0;
  }
  svg {
    margin-right: 2rem;
    width: auto;
    height: 1.5rem;
    display: inline-block;
    path {
      stroke: ;
    }
  }
  a {
    font-weight: normal;
    margin: 1rem 0;
    line-height: 1.15;
    display: block;
    span {
      border-bottom: 1px solid ${(props) => props.theme.colours.black};
    }
  }
`

function ServiceSectionProjectTile({ image, children }) {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  })

  return (
    <Holder ref={ref}>
      <ImageHolder inView={inView}>
        <Image
          style={{
            width: "100%",
            height: "100%",
          }}
          imgStyle={{
            objectPosition: "center center",
          }}
          imgName={image}
        />
      </ImageHolder>
      <TextHolder>
        <Bg />
        <Container margins={false}>
          <TextInner>{children}</TextInner>
        </Container>
      </TextHolder>
    </Holder>
  )
}

ServiceSectionProjectTile.propTypes = {
  image: PropTypes.string.isRequired,
}

export default ServiceSectionProjectTile
