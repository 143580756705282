import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import Container from "../atoms/Container"

const ListsHolder = styled.div`
  background-color: ${(props) => props.theme.colours.grey};
  padding: 4rem 0;
`

const Lists = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4rem;
  @media (${(props) => props.theme.breakpoints.lg}) {
    grid-template-columns: 1fr 1fr;
  }
  ul {
    list-style: none;
    padding-left: 0;
  }
  h3 {
    font-weight: bold;
    font-family: "Karla", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Helvetica, Arial, sans-serif;
    ${(props) => props.theme.fluidType(1)};
    margin-bottom: 1.5rem;
    + p {
      @media (${(props) => props.theme.breakpoints.lg}) {
        min-height: 85px;
      }
      font-weight: bold;
      margin-bottom: 1.5rem;
    }
  }
  > div {
    > :first-child {
      margin-top: 0;
    }
    > :last-child {
      margin-bottom: 0;
    }
  }
`

function ServiceSectionLists({ children }) {
  return (
    <ListsHolder>
      <Container>
        <Lists>{children}</Lists>
      </Container>
    </ListsHolder>
  )
}

ServiceSectionLists.propTypes = {
  propName: PropTypes.string,
}

ServiceSectionLists.defaultProps = {
  propName: "Stranger",
}

export default ServiceSectionLists
