import React from 'react';
import styled from 'styled-components';
import Tick from '../../assets/tick.inline.svg';

const Holder = styled.li`
  display: grid;
  grid-template-columns: 1rem 1fr;
  grid-gap: 2rem;
  svg {
    width: 100%;
    height: auto;
    display: block;
    margin-top: 0.5rem;
  }
`;

function TickLi( {children}) {
    return (
      <Holder>
          <Tick/>
          <span>{children}</span>
      </Holder>
    )
}

export default TickLi;
